<template>
    <div>
        <section id="priloha">

            <div class="title">
                <a href="javascript:history.back()" class="back-button">Späť</a>
                <span class="custom-label">Názov dokladu prílohy</span>
                <h2 class="mb-4">{{ requiredDocs[id].name }}</h2>
                <span class="custom-label">Kód dokladu prílohy</span>
                <h2 class="mb-0">{{ requiredDocs[id].code }}</h2>
            </div>

            <div class="content">
                <span class="custom-label">Informácie o doklade prílohy</span>
                <p>{{ requiredDocs[id].info }}</p>
            </div>

        </section>
    </div>
</template>

<script>
    export default {
        props: ['id', 'requiredDocs'],
        mounted() {
            if ( this.requiredDocs.length === 0 ) {
                this.$emit('updateAttachments')
            }
        }
    }
</script>

<style>
</style>