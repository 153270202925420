<template>

    <div>

        <div v-if="result.i2 !== undefined">
            <span class="cislo">{{ groups[result.i1].code }}</span>
            <p>{{ groups[result.i1].name }}</p>
        </div>
        <div v-if="result.i3 !== undefined">
            <span class="cislo">{{ groups[result.i1].subgroups[result.i2].code }}</span>
            <p>{{ groups[result.i1].subgroups[result.i2].name }}</p>
        </div>
        <div v-if="result.i4 !== undefined">
            <span class="cislo">{{ groups[result.i1].subgroups[result.i2].subgroups[result.i3].code }}</span>
            <p>{{ groups[result.i1].subgroups[result.i2].subgroups[result.i3].name }}</p>
        </div>
        <div>
            <span class="cislo">{{ result.code }}</span>
            <p v-html="highlightText(result.name)"></p>
        </div>

    </div>

</template>

<script>
    export default {
        props: ['groups', 'result', 'needle'],
        methods: {
            highlightText: function (resultName) {
                return resultName.replace(new RegExp(this.$props.needle, "gi"), match => {
                    return '<span class="hladany-vyraz">' + match + '</span>';
                });
            }
        }
    }
</script>

<style>
</style>