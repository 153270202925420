var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("span", { staticClass: "custom-label" }, [_vm._v("Klasifikácia")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.subgroup.classification))]),
      _vm._v(" "),
      _c("span", { staticClass: "custom-label" }, [
        _vm._v("Deklarované požiarne parametre")
      ]),
      _vm._v(" "),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.subgroup.fireParametersDeclared) }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "custom-label" }, [
        _vm._v("Preukázanie požiarneho parametra")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.subgroup.fireParametersDemonstration))]),
      _vm._v(" "),
      _c("span", { staticClass: "custom-label" }, [
        _vm._v("Ďalšie požiadavky a poznámky")
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.subgroup.other))]),
      _vm._v(" "),
      _c("span", { staticClass: "custom-label" }, [
        _vm._v("Zoznam požadovaných dokladov")
      ]),
      _vm._v(" "),
      _c("subgroup-detail-docs-list", {
        attrs: {
          "required-docs-list": _vm.subgroup.requiredDocsList,
          "required-docs": _vm.requiredDocs
        }
      }),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "poznamka" }, [
      _c("p", [
        _vm._v(
          '* Pokiaľ je v zozname požadovaných dokladov pod položkou „POVINNÁ PRÍLOHA X*" uvedených viac dokladov príloh s rôznym názvom, tak je potrebné predložiť aspoň jeden z uvedených dokladov.'
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }