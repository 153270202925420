<template>

    <div>

        <table>
            <tr>
                <td class="table-label">P.Č.</td>
                <td class="table-label">Názov dokladu prílohy</td>
            </tr>
        </table>

        <table v-for="(attachment, outer) in requiredDocsList" :key="outer">
            <tr>
                <td colspan="2" class="table-head">{{ attachment.name }}*</td>
            </tr>
            <tr v-for="(item, inner) in attachment.items" :key="inner">
                <td class="table-number">{{ item.index }}</td>
                <td class="table-text">
                    <router-link :to="{ name: 'prilohaDetail', params: { id: item.key }}">{{ requiredDocs[item.key].name }}</router-link>
                </td>
            </tr>
        </table>

    </div>

</template>

<script>
    export default {
        props: ['requiredDocsList', 'requiredDocs']
    }
</script>

<style>
</style>