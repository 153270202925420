var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "podskupiny-pk" } }, [
    _vm.sub2groupId !== undefined
      ? _c(
          "div",
          [
            _c("subgroups-title", {
              attrs: {
                code:
                  _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].subgroups[
                    _vm.sub2groupId
                  ].code,
                name:
                  _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].subgroups[
                    _vm.sub2groupId
                  ].name
              }
            }),
            _vm._v(" "),
            _c("subgroups-list", {
              attrs: {
                subgroups:
                  _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].subgroups[
                    _vm.sub2groupId
                  ].subgroups,
                "group-id": _vm.groupId,
                "subgroup-id": _vm.subgroupId,
                "sub2group-id": _vm.sub2groupId
              }
            })
          ],
          1
        )
      : _vm.subgroupId !== undefined
      ? _c(
          "div",
          [
            _c("subgroups-title", {
              attrs: {
                code: _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].code,
                name: _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].name
              }
            }),
            _vm._v(" "),
            _c("subgroups-list", {
              attrs: {
                subgroups:
                  _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].subgroups,
                "group-id": _vm.groupId,
                "subgroup-id": _vm.subgroupId
              }
            })
          ],
          1
        )
      : _vm.groupId !== undefined
      ? _c(
          "div",
          [
            _c("subgroups-title", {
              attrs: {
                code: _vm.groups[_vm.groupId].code,
                name: _vm.groups[_vm.groupId].name
              }
            }),
            _vm._v(" "),
            _c("subgroups-list", {
              attrs: {
                subgroups: _vm.groups[_vm.groupId].subgroups,
                "group-id": _vm.groupId
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }