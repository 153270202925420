var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "signup" } }, [
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "54",
          height: "54",
          viewBox: "0 0 54 54"
        }
      },
      [
        _c(
          "g",
          {
            attrs: {
              id: "Group_34",
              "data-name": "Group 34",
              transform: "translate(-183 -63)"
            }
          },
          [
            _c("line", {
              attrs: {
                id: "Line_24",
                "data-name": "Line 24",
                x1: "18",
                y2: "18",
                transform: "translate(201 81)",
                fill: "none",
                stroke: "#dc3c50",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "4"
              }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: {
                id: "Line_25",
                "data-name": "Line 25",
                x2: "18",
                y2: "18",
                transform: "translate(201 81)",
                fill: "none",
                stroke: "#dc3c50",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "4"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_1",
                "data-name": "Path 1",
                d:
                  "M185,99.748V80.25L200.265,65h19.47L235,80.25v19.5L219.735,115h-19.47L185,99.748Z",
                fill: "none",
                stroke: "#dc3c50",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "4",
                "fill-rule": "evenodd"
              }
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("h1", { domProps: { innerHTML: _vm._s(_vm.showErrorTitle()) } }),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.showErrorContent()) } }),
    _vm._v(" "),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.showErrorButton()) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }