<template>
    <div>
        <main>
            <router-view
                    @updateAttachments="apiAttachments"
                    @updateProfile="apiProfile"
                    @updateGroups="apiGroups"
                    @checkAndUpdateData="checkAndUpdateData"
                    :groups="groups"
                    :requiredDocs="requiredDocs"
                    :profile="profile"
            />
        </main>
        <div class="nav-menu" v-if="$route.meta.showMenu == true">
            <nav>
                <ul>
                    <li v-for="(link, index) in links" :key="index">
                        <router-link :to="link.to">
                            <img height="27" :src="$assetsUrl + link.iconSrc">
                            {{ link.name }}
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<style>
</style>

<script>
    import bugsnagClient from './bugsnag'
    export default {
        data: () => ({
            links: [
                {
                    name: 'Domov',
                    to: '/home',
                    iconSrc: 'img/home-i.svg'
                },
                {
                    name: 'Hľadať',
                    to: '/search',
                    iconSrc: 'img/search-i.svg'
                },
                {
                    name: 'Môj profil',
                    to: '/profile',
                    iconSrc: 'img/profile-i.svg'
                },
                {
                    name: 'Kontakt',
                    to: '/contact',
                    iconSrc: 'img/contact-i.svg'
                }
            ],
            groups: [],
            requiredDocs: [],
            profile: {},
            deferredPrompt: {}
        }),
        methods: {
            checkAndUpdateData: function () {
                this.apiPing()
            },

            updateAllData: async function () {
                await this.apiGroups()
                await this.apiAttachments()
                await this.apiProfile()

                this.$router.push({ name: 'skupinyPK' })
            },

            // ping if user is online
            apiPing: function () {
                this.axios
                    .get('ping')
                    .then( _ => {
                        // validate data
                        this.apiValidate()
                    })
                    .catch(error => {
                        if (error.request) {
                            // it is ok, user is offline
                            sessionStorage.setItem('okto.opk.pwa.validated', true)
                            this.$router.push({ name: 'skupinyPK' })
                        } else {
                            // it is error
                            bugsnagClient.notify(new Error(error.message))
                        }
                    })
            },

            // validate user data
            apiValidate: function () {
                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                };

                this.axios
                    .post('validate', 'email=' + localStorage.getItem('okto.opk.pwa.email') + '&hash=' + localStorage.getItem('okto.opk.pwa.deviceHash'), axiosConfig)
                    .then( _ => {
                        // device is online and passed validation
                        sessionStorage.setItem('okto.opk.pwa.validated', true)
                        this.updateAllData()
                    })
                    .catch(error => {
                        if (error.response) {
                            if ( error.response.status === 400 ) {
                                // device did not pass validation - device has been unregistered
                                localStorage.removeItem('okto.opk.pwa.email')
                                localStorage.removeItem('okto.opk.pwa.deviceHash')
                                this.$router.push({ name: 'register' })
                            }

                        } else {
                            // it is error
                            bugsnagClient.notify(new Error(error.message))
                        }

                    })
            },

            // download groups from API
            apiGroups: function () {
                return this.axios
                    .get('groups')
                    .then(response => {
                        this.groups = response.data
                    })
                    .catch(error => {
                        // it is error
                        bugsnagClient.notify(new Error(error.message))
                    })
            },

            // download requiredDocs from API
            apiAttachments: function () {
                return this.axios
                    .get('attachments')
                    .then(response => {
                        this.requiredDocs = response.data
                    })
                    .catch(error => {
                        // it is error
                        bugsnagClient.notify(new Error(error.message))
                    })
            },

            // download profile from API
            apiProfile: function () {
                return this.axios
                    .get('profile?email=' + localStorage.getItem('okto.opk.pwa.email'))
                    .then(response => {
                        this.profile = response.data
                    })
                    .catch(error => {
                        // it is error
                        bugsnagClient.notify(new Error(error.message))
                    })
            },

        },
        mounted() {
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.deferredPrompt = e;
            });
        }
    }
</script>