<template>

    <section id="profile">

        <div class="title">
            <!--<a href="" class="sign-out"><img :src="$assetsUrl + 'img/sign-out.svg'" alt=""></a>-->
            <img class="profile-head" :src="$assetsUrl + 'img/person.svg'">
            <h2>{{ profile.name }}</h2>
            <span class="email">{{ profile.email }}</span>
        </div>

        <div class="active-devices">

            <span class="custom-label">Moje aktívne zariadenia</span>

            <div v-if="profile.devices && profile.devices.length > 0" class="devices">
                <div v-for="device in profile.devices" class="device">
                    <div class="wrap">
                        <img :src="$assetsUrl + 'img/iphone.svg'">
                        <div class="device-name">
                            <h2>{{ device.name }}</h2>
                            <span>Posledné prihlásenie: {{ device.last_login | moment('D.M.YYYY') }}</span>
                        </div>
                    </div>
                    <img
                            v-if="deviceHash !== device.hash"
                            class="close"
                            @click='removeDevice(device.hash)'
                            :src="$assetsUrl + 'img/close.svg'"
                            alt=""
                    >
                </div>
            </div>
            <div v-else>
                <p>Nemáte žiadne aktívne zariadenia.</p>
            </div>

            <div class="free-devices">
                <p>Počet voľných zariadení: <span class="number-left">{{ profile.freeLicenses }}</span></p>
            </div>

        </div>

    </section>

</template>

<script>
    import bugsnagClient from '../bugsnag'
    export default {
        props: ['profile'],
        data: () => ({
            deviceHash: localStorage.getItem('okto.opk.pwa.deviceHash'),
        }),
        methods: {
            removeDevice: function (hash) {

                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                };

                this.axios
                    .post('remove-device', 'email=' + this.$props.profile.email + '&device=' + hash, axiosConfig)
                    .then(response => {
                        this.$emit('updateProfile', response.data)
                    })
                    .catch(error => {
                        bugsnagClient.notify(new Error(error.message))
                    })
            }
        },
        mounted() {
            if (this.profile.email === undefined ) {
                this.$emit('updateProfile')
            }
        },
    }
</script>

<style>
</style>