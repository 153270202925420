import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Loading from './components/Loading.vue'
import Groups from "./components/Groups.vue";
import Subgroups from "./components/Subgroups.vue";
import SubgroupDetail from "./components/SubgroupDetail.vue";
import AttachmentDetail from "./components/AttachmentDetail.vue";
import Search from "./components/Search.vue";
import Profile from "./components/Profile.vue";
import Register from './components/Register.vue'
import Contact from './components/Contact.vue'
import Error from './components/Error.vue'

// Fallback page
// import PageNotFound from '@/components/PageNotFound'

Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'loading',
            component: Loading,
            meta: {
                requiresAuth: false,
                showMenu: false
            },
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                requiresAuth: false,
                showMenu: false
            }
        },
        {
            path: '/home',
            name: 'skupinyPK',
            component: Groups,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/podskupiny/:groupId/:subgroupId?/:sub2groupId?',
            name: 'podskupinaPK',
            component: Subgroups,
            props: true,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/podskupina/:groupId/:subgroupId?/:sub2groupId?/:sub3groupId?',
            name: 'podskupinaDetailPK',
            component: SubgroupDetail,
            props: true,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/priloha/:id',
            name: 'prilohaDetail',
            component: AttachmentDetail,
            props: true,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/search',
            name: 'search',
            component: Search,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/profile',
            name: 'profil',
            component: Profile,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/contact',
            name: 'kontakt',
            component: Contact,
            meta: {
                requiresAuth: true,
                showMenu: true
            }
        },
        {
            path: '/error',
            name: 'error',
            component: Error,
            meta: {
                requiresAuth: false,
                showMenu: false
            }
        },

        // {
        //     path: '**',
        //     name: 'PageNotFound',
        //     component: PageNotFound
        // }
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})