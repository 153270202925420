<template>

    <div class="list">

        <ol class="main-category">

            <li v-for="(subgroup, index) in subgroups" :key="index">

                <span v-if="sub2groupId !== undefined">

                    <router-link
                            v-if=" !subgroup.subgroups"
                            :to="{ name: 'podskupinaDetailPK', params: { groupId: groupId, subgroupId: subgroupId, sub2groupId: sub2groupId, sub3groupId: index }}">
                        {{ subgroup.name }}
                    </router-link>
                    <!-- THIS SITUATION SHOULD NOT HAPPEN -->
                    <!--<router-link-->
                    <!--v-else-->
                    <!--:to="{ name: 'podskupinaPK', params: { groupId: groupId, subgroupId: subgroupId, sub2groupId: sub2groupId, sub3groupId: index }}">-->
                    <!--{{ subgroup.name }}-->
                    <!--</router-link>-->

                </span>

                <span v-else-if="subgroupId !== undefined">

                    <router-link
                            v-if=" !subgroup.subgroups "
                            :to="{ name: 'podskupinaDetailPK', params: { groupId: groupId, subgroupId: subgroupId, sub2groupId: index }}">
                        {{ subgroup.name }}
                    </router-link>
                    <router-link
                            v-else
                            :to="{ name: 'podskupinaPK', params: { groupId: groupId, subgroupId: subgroupId, sub2groupId: index }}">
                        {{ subgroup.name }}
                    </router-link>

                </span>

                <span v-else-if="groupId !== undefined">

                    <router-link
                            v-if=" !subgroup.subgroups "
                            :to="{ name: 'podskupinaDetailPK', params: { groupId: groupId, subgroupId: index }}">
                        {{ subgroup.name }}
                    </router-link>
                    <router-link
                            v-else
                            :to="{ name: 'podskupinaPK', params: { groupId: groupId, subgroupId: index }}">
                        {{ subgroup.name }}
                    </router-link>

                </span>

            </li>

        </ol>

    </div>

</template>

<script>
    export default {
        props: ['groupId', 'subgroupId', 'sub2groupId', 'subgroups']
    }
</script>

<style>
</style>