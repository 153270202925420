var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "skupiny-pk" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "list" }, [
      _c(
        "ol",
        { staticClass: "main-category" },
        _vm._l(_vm.groups, function(group, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "podskupinaPK", params: { groupId: index } }
                  }
                },
                [_vm._v(_vm._s(group.name))]
              )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Skupiny PK")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }