<template>
    <section id="skupiny-pk">
        <div class="title">
            <h1>Skupiny PK</h1>
        </div>

        <div class="list">
            <ol class="main-category">
                <li v-for="(group, index) in groups" :key="index">
                    <router-link :to="{ name: 'podskupinaPK', params: { groupId: index } }">{{ group.name }}</router-link>
                </li>
            </ol>
        </div>
    </section>
</template>

<script>
    export default {
        props: ['groups'],
        mounted() {
            if ( this.groups.length === 0 ) {
                this.$emit('updateGroups')
            }
        }
    }
</script>

<style>
</style>