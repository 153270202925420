var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title" }, [
    _c(
      "a",
      {
        staticClass: "back-button",
        attrs: { href: "javascript:history.back()" }
      },
      [_vm._v("Späť")]
    ),
    _vm._v(" "),
    _c("h2", [_c("span", [_vm._v(_vm._s(_vm.code))]), _vm._v(_vm._s(_vm.name))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }