var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "profile" } }, [
    _c("div", { staticClass: "title" }, [
      _c("img", {
        staticClass: "profile-head",
        attrs: { src: _vm.$assetsUrl + "img/person.svg" }
      }),
      _vm._v(" "),
      _c("h2", [_vm._v(_vm._s(_vm.profile.name))]),
      _vm._v(" "),
      _c("span", { staticClass: "email" }, [_vm._v(_vm._s(_vm.profile.email))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "active-devices" }, [
      _c("span", { staticClass: "custom-label" }, [
        _vm._v("Moje aktívne zariadenia")
      ]),
      _vm._v(" "),
      _vm.profile.devices && _vm.profile.devices.length > 0
        ? _c(
            "div",
            { staticClass: "devices" },
            _vm._l(_vm.profile.devices, function(device) {
              return _c("div", { staticClass: "device" }, [
                _c("div", { staticClass: "wrap" }, [
                  _c("img", {
                    attrs: { src: _vm.$assetsUrl + "img/iphone.svg" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "device-name" }, [
                    _c("h2", [_vm._v(_vm._s(device.name))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "Posledné prihlásenie: " +
                          _vm._s(
                            _vm._f("moment")(device.last_login, "D.M.YYYY")
                          )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.deviceHash !== device.hash
                  ? _c("img", {
                      staticClass: "close",
                      attrs: { src: _vm.$assetsUrl + "img/close.svg", alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeDevice(device.hash)
                        }
                      }
                    })
                  : _vm._e()
              ])
            }),
            0
          )
        : _c("div", [_c("p", [_vm._v("Nemáte žiadne aktívne zariadenia.")])]),
      _vm._v(" "),
      _c("div", { staticClass: "free-devices" }, [
        _c("p", [
          _vm._v("Počet voľných zariadení: "),
          _c("span", { staticClass: "number-left" }, [
            _vm._v(_vm._s(_vm.profile.freeLicenses))
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }