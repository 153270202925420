<template>

    <section id="podskupiny-pk">

        <div v-if="sub2groupId !== undefined">

            <subgroups-title
                    :code="groups[groupId].subgroups[subgroupId].subgroups[sub2groupId].code"
                    :name="groups[groupId].subgroups[subgroupId].subgroups[sub2groupId].name"
            >
            </subgroups-title>
            <subgroups-list
                    :subgroups="groups[groupId].subgroups[subgroupId].subgroups[sub2groupId].subgroups"
                    :group-id="groupId"
                    :subgroup-id="subgroupId"
                    :sub2group-id="sub2groupId"
            >
            </subgroups-list>

        </div>

        <div v-else-if="subgroupId !== undefined">

            <subgroups-title
                    :code="groups[groupId].subgroups[subgroupId].code"
                    :name="groups[groupId].subgroups[subgroupId].name"
            >
            </subgroups-title>
            <subgroups-list
                    :subgroups="groups[groupId].subgroups[subgroupId].subgroups"
                    :group-id="groupId"
                    :subgroup-id="subgroupId"
            >
            </subgroups-list>

        </div>

        <div v-else-if="groupId !== undefined">

            <subgroups-title
                    :code="groups[groupId].code"
                    :name="groups[groupId].name"
            >
            </subgroups-title>
            <subgroups-list
                    :subgroups="groups[groupId].subgroups"
                    :group-id="groupId"
            >
            </subgroups-list>

        </div>

    </section>

</template>

<script>
    import SubgroupsList from "./partials/SubgroupsList.vue";
    import SubgroupsTitle from "./partials/SubgroupsTitle.vue";
    export default {
        components: {SubgroupsTitle, SubgroupsList},
        props: ['groupId', 'subgroupId', 'sub2groupId', 'groups'],
        mounted() {
            if ( this.groups.length === 0 ) {
                this.$emit('updateGroups')
            }
        }
    }
</script>

<style>
</style>