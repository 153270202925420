<template>

    <div class="content">
        <span class="custom-label">Klasifikácia</span>
        <p>{{ subgroup.classification }}</p>
        <span class="custom-label">Deklarované požiarne parametre</span>
        <p v-html="subgroup.fireParametersDeclared"></p>
        <span class="custom-label">Preukázanie požiarneho parametra</span>
        <p>{{ subgroup.fireParametersDemonstration }}</p>
        <span class="custom-label">Ďalšie požiadavky a poznámky</span>
        <p>{{ subgroup.other }}</p>
        <span class="custom-label">Zoznam požadovaných dokladov</span>

        <subgroup-detail-docs-list
                :required-docs-list="subgroup.requiredDocsList"
                :required-docs="requiredDocs"
        ></subgroup-detail-docs-list>

        <div class="poznamka">
            <p>* Pokiaľ je v zozname požadovaných dokladov pod položkou „POVINNÁ PRÍLOHA X*" uvedených viac dokladov príloh s rôznym názvom, tak je potrebné predložiť aspoň jeden z uvedených dokladov.</p>
        </div>
    </div>

</template>

<script>
    import SubgroupDetailDocsList from "./SubgroupDetailDocsList.vue";
    export default {
        components: { SubgroupDetailDocsList },
        props: ['subgroup', 'requiredDocs']
    }
</script>

<style>
</style>