<template>

    <div class="title">

        <a href="javascript:history.back()" class="back-button">Späť</a>
        <span class="custom-label">Názov nadradenej skupiny</span>
        <h2><span>{{ group.code }}</span> {{ group.name }}</h2>

        <div v-if="subgroupId !== undefined" class="mt-4">

            <span class="custom-label">Názov skupiny alebo podskupiny</span>
            <h2>
                <span>{{ group.subgroups[subgroupId].code }}</span>
                {{ group.subgroups[subgroupId].name }}
            </h2>

        </div>

        <div v-if="sub2groupId !== undefined" class="mt-4">

            <span class="custom-label">Názov skupiny alebo podskupiny</span>

            <h2>
                <span>{{ group.subgroups[subgroupId].subgroups[sub2groupId].code }}</span>
                {{ group.subgroups[subgroupId].subgroups[sub2groupId].name }}
            </h2>

        </div>

        <div v-if="sub3groupId !== undefined" class="mt-4">

            <span class="custom-label">Názov skupiny alebo podskupiny</span>

            <h2>
                <span>{{ group.subgroups[subgroupId].subgroups[sub2groupId].subgroups[sub3groupId].code  }}</span>
                {{ group.subgroups[subgroupId].subgroups[sub2groupId].subgroups[sub3groupId].name }}
            </h2>

        </div>

    </div>

</template>

<script>
    export default {
        props: ['group', 'subgroupId', 'sub2groupId', 'sub3groupId']
    }
</script>

<style>
</style>