<template>

    <section id="contact">

        <div class="title">
            <h2>Nahlásenie chyby v aplikácii</h2>
        </div>

        <div class="content">

            <p>Uveďte prosím čo najpodrobnejší popis chyby (čo ste práve v aplikácii robili, na čo ste klikli, ako sa chyba prejavila, ...)</p>

            <form>
                <div class="form-group">
                    <textarea v-model="feedbackText" class="form-control" rows="10" placeholder="Vaša správa"></textarea>
                </div>

                <button v-if=" !btnDisabled " @click="sendFeedback" type="submit" class="btn btn-primary mb-3">Odoslať</button>
                <button v-else type="submit" class="btn btn-primary">Odosielam...</button>

                <p v-if="successMessage">Chyba odoslaná</p>
            </form>

        </div>

    </section>

</template>

<script>
    import bugsnagClient from '../bugsnag'
    export default {
        props: ['profile'],
        data: () => ({
            feedbackText: '',
            btnDisabled: false,
            successMessage: false
        }),
        methods: {
            sendFeedback: function () {

                this.btnDisabled = true

                let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                };

                this.axios
                    .post('feedback', 'email=' + this.profile.email + '&feedback=' + this.feedbackText, axiosConfig)
                    .then(_ => {
                        this.successMessage = true
                        this.btnDisabled = false
                        this.feedbackText = ''
                    })
                    .catch(error => {
                        bugsnagClient.notify(new Error(error.message))
                    })
            }
        },
        mounted() {

            if (this.profile.email === undefined ) {
                this.$emit('updateProfile')
            }
        }
    }
</script>

<style>
</style>