var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c(
      "ol",
      { staticClass: "main-category" },
      _vm._l(_vm.subgroups, function(subgroup, index) {
        return _c("li", { key: index }, [
          _vm.sub2groupId !== undefined
            ? _c(
                "span",
                [
                  !subgroup.subgroups
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "podskupinaDetailPK",
                              params: {
                                groupId: _vm.groupId,
                                subgroupId: _vm.subgroupId,
                                sub2groupId: _vm.sub2groupId,
                                sub3groupId: index
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(subgroup.name) +
                              "\n                "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm.subgroupId !== undefined
            ? _c(
                "span",
                [
                  !subgroup.subgroups
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "podskupinaDetailPK",
                              params: {
                                groupId: _vm.groupId,
                                subgroupId: _vm.subgroupId,
                                sub2groupId: index
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(subgroup.name) +
                              "\n                "
                          )
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "podskupinaPK",
                              params: {
                                groupId: _vm.groupId,
                                subgroupId: _vm.subgroupId,
                                sub2groupId: index
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(subgroup.name) +
                              "\n                "
                          )
                        ]
                      )
                ],
                1
              )
            : _vm.groupId !== undefined
            ? _c(
                "span",
                [
                  !subgroup.subgroups
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "podskupinaDetailPK",
                              params: {
                                groupId: _vm.groupId,
                                subgroupId: index
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(subgroup.name) +
                              "\n                "
                          )
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "podskupinaPK",
                              params: {
                                groupId: _vm.groupId,
                                subgroupId: index
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(subgroup.name) +
                              "\n                "
                          )
                        ]
                      )
                ],
                1
              )
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }