<template>

    <section id="skupina">

        <div>

            <subgroup-detail-title
                    v-if="groups.length > 0"
                    :group="groups[groupId]"
                    :subgroup-id="subgroupId"
                    :sub2group-id="sub2groupId"
                    :sub3group-id="sub3groupId"
            ></subgroup-detail-title>

            <subgroup-detail-content
                    v-if="sub3groupId !== undefined && groups.length > 0"
                    :subgroup="groups[groupId].subgroups[subgroupId].subgroups[sub2groupId].subgroups[sub3groupId]"
                    :required-docs="requiredDocs"
            ></subgroup-detail-content>

            <subgroup-detail-content
                    v-else-if="sub2groupId !== undefined && groups.length > 0"
                    :subgroup="groups[groupId].subgroups[subgroupId].subgroups[sub2groupId]"
                    :required-docs="requiredDocs"
            ></subgroup-detail-content>

            <subgroup-detail-content
                    v-else-if="subgroupId !== undefined && groups.length > 0"
                    :subgroup="groups[groupId].subgroups[subgroupId]"
                    :required-docs="requiredDocs"
            ></subgroup-detail-content>

        </div>
    </section>
</template>

<script>
    import SubgroupDetailTitle from "./partials/SubgroupDetailTitle.vue";
    import SubgroupDetailContent from "./partials/SubgroupDetailContent.vue";
    export default {
        components: {SubgroupDetailTitle, SubgroupDetailContent},
        props: ['groupId', 'subgroupId', 'sub2groupId', 'sub3groupId', 'groups', 'requiredDocs'],
        mounted() {
            if ( this.groups.length === 0 ) {
                this.$emit('updateGroups')
            }
            if ( this.requiredDocs.length === 0 ) {
                this.$emit('updateAttachments')
            }
        }
    }

</script>

<style>
</style>