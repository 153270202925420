<template>

    <section id="signup">

        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
            <g id="Group_34" data-name="Group 34" transform="translate(-183 -63)">
                <line id="Line_24" data-name="Line 24" x1="18" y2="18" transform="translate(201 81)" fill="none" stroke="#dc3c50" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                <line id="Line_25" data-name="Line 25" x2="18" y2="18" transform="translate(201 81)" fill="none" stroke="#dc3c50" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
                <path id="Path_1" data-name="Path 1" d="M185,99.748V80.25L200.265,65h19.47L235,80.25v19.5L219.735,115h-19.47L185,99.748Z" fill="none" stroke="#dc3c50" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" fill-rule="evenodd"/>
            </g>
        </svg>

        <h1 v-html="showErrorTitle()"></h1>

        <p v-html="showErrorContent()"></p>

        <div v-html="showErrorButton()"></div>

    </section>
</template>

<script>
    export default {
        data: () => ({
            errorCode: 'generalError',
            errors:{
                    'notRegistered':
                        {
                            title: 'Emailová adresa nebola nájdená v databáze užívateľov riadnej aplikácie OPK',
                            content: 'Progresívna Webová Aplikácia obsahujúca prehľad požiarnych konštrukcií je určená pre užívateľov riadnej aplikácie OPK s platnou licenciou. Pre používanie PWA požiarnych konštrukcií sa prosím zaregistrujte do riadnej aplikácie OPK na <a href="https://opk.appo.sk/uvod/">opk.appo.sk</a>',
                            button: '<a href="https://opk.appo.sk/uvod/" class="btn btn-primary">Registrovať sa</a>'
                        },
                    'notActivated':
                        {
                            title: 'Používateľ nie je aktivovaný',
                            content: '<p>Registrácia používateľa nie je dokončená kliknutím na aktivačný odkaz, ktorý bol odoslaný na emailovú adresu zadanú pri registrácii používateľa. Prosím aktivujte si Váš používateľský účet.</p>'
                        },
                    'limitExceeded':
                        {
                            title: 'Presiahnutie počtu aktívnych zariadení',
                            content: '<p>Zaznamenali sme presiahnutie počtu aktívnych zariadení, evidovaných na Vašu emailovú adresu. Pre pridanie nového zariadenia, postupujte prosím podľa pokynov zaslaných na Vašu emailovú adresu. Ďakujeme.</p>'
                        },
                    'connectionError':
                        {
                            title: 'Chyba pripojenia na internet',
                            content: '<p>Skontrolujte vaše internetové pripojenie, prosím. Pri registrácii zariadenia do Progresívnej webovej aplikácie musíte mať pripojenie na internet, aby sme mohli overiť Váš email a aby si aplikácia mohla stiahnuť údaje.</p>'
                        },
                    'generalError':
                        {
                            title: 'V aplikácii nastala chyba',
                            content: '<p>V prípade, že sa chyba opakuje, kontaktujte prosím správcu aplikácie na emailovú adresu <a href="mailto:info@appo.sk">info@appo.sk</a> a uveďte čo najbližšie podrobnosti ako k chybe dochádza.</p>'
                        },
            },
        }),
        methods: {
            showErrorTitle: function () {
                return this.errors[this.errorCode].title
            },
            showErrorContent: function () {
                return this.errors[this.errorCode].content
            },
            showErrorButton: function () {
                return this.errors[this.errorCode].button
            },
        },
        mounted() {
            this.errorCode = this.$route.params.errorCode
        }
    }
</script>

<style>
</style>