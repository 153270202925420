<template>

    <section id="search">

        <div class="title">
            <h2>Vyhľadávanie</h2>
        </div>

        <div class="content">

            <form>
                <div class="form-group">
                    <input
                            v-model="needle"
                            @keyup="searchData"
                            type="text"
                            class="form-control"
                            placeholder="Zadajte hľadaný výraz"
                    >
                </div>
                <p v-if="searchResult.length == 0">Pre začatie vyhľadávania<br>zadajte hľadaný výraz.</p>

            </form>

            <ul>
                <li v-for="(result) in searchResult">

                    <router-link
                            v-if="result.hasSubgroups"
                            :to="{ name: 'podskupinaPK', params: { groupId: result.i1, subgroupId: result.i2, sub2groupId: result.i3, sub3groupId: result.i4  } }">
                        <search-list-item
                                :groups="groups"
                                :result="result"
                                :needle="needle">
                        </search-list-item>
                    </router-link>

                    <router-link
                            v-else
                            :to="{ name: 'podskupinaDetailPK', params: { groupId: result.i1, subgroupId: result.i2, sub2groupId: result.i3, sub3groupId: result.i4  } }">
                        <search-list-item
                            :groups="groups"
                            :result="result"
                            :needle="needle">
                        </search-list-item>
                    </router-link>

                </li>
            </ul>

        </div>

    </section>

</template>

<script>
    import SearchListItem from "./partials/SearchListItem.vue";
    export default {
        components: {SearchListItem},
        props: ['groups'],
        data: function() {
            return  {
                needle: '',
                searchResult: []
            }
        },
        methods: {
            searchData: function () {

                this.$data.searchResult = []

                if ( this.$data.needle ) {
                    this.filterData(this.$props.groups)
                }

            },
            filterData: function (groups) {
                var self = this;

                groups.forEach(function (group, i1) {

                    if ( self.isIn(group) ) {
                        self.$data.searchResult.push( { code: group.code, name: group.name, hasSubgroups: group.subgroups, i1: i1 } )
                    }

                    if ( group.subgroups ) {
                        group.subgroups.forEach(function (subgroup, i2) {

                            if ( self.isIn(subgroup) ) {
                                self.$data.searchResult.push( { code: subgroup.code, name: subgroup.name, hasSubgroups: subgroup.subgroups, i1: i1, i2: i2 } )
                            }

                            if ( subgroup.subgroups ) {
                                subgroup.subgroups.forEach(function (sub2group, i3) {

                                    if ( self.isIn(sub2group) ) {
                                        self.$data.searchResult.push( { code: sub2group.code, name: sub2group.name, hasSubgroups: sub2group.subgroups, i1: i1, i2: i2, i3: i3 } )
                                    }

                                    if ( sub2group.subgroups ) {
                                        sub2group.subgroups.forEach(function (sub3group, i4) {

                                            if ( self.isIn(sub3group) ) {
                                                self.$data.searchResult.push( { code: sub3group.code, name: sub3group.name, hasSubgroups: group.sub3groups, i1: i1, i2: i2, i3: i3, i4: i4 } )
                                            }

                                        })
                                    }

                                })
                            }

                        })
                    }

                })

            },
            isIn: function (haystackGroup) {
                return haystackGroup.name.toLowerCase().includes(this.$data.needle.toLowerCase())
            }
        },
        mounted() {
            if ( this.groups.length === 0 ) {
                this.$emit('updateGroups')
            }
        }
    }
</script>

<style>
</style>