var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "search" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("form", [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.needle,
                expression: "needle"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "Zadajte hľadaný výraz" },
            domProps: { value: _vm.needle },
            on: {
              keyup: _vm.searchData,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.needle = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _vm.searchResult.length == 0
          ? _c("p", [
              _vm._v("Pre začatie vyhľadávania"),
              _c("br"),
              _vm._v("zadajte hľadaný výraz.")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.searchResult, function(result) {
          return _c(
            "li",
            [
              result.hasSubgroups
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "podskupinaPK",
                          params: {
                            groupId: result.i1,
                            subgroupId: result.i2,
                            sub2groupId: result.i3,
                            sub3groupId: result.i4
                          }
                        }
                      }
                    },
                    [
                      _c("search-list-item", {
                        attrs: {
                          groups: _vm.groups,
                          result: result,
                          needle: _vm.needle
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "podskupinaDetailPK",
                          params: {
                            groupId: result.i1,
                            subgroupId: result.i2,
                            sub2groupId: result.i3,
                            sub3groupId: result.i4
                          }
                        }
                      }
                    },
                    [
                      _c("search-list-item", {
                        attrs: {
                          groups: _vm.groups,
                          result: result,
                          needle: _vm.needle
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h2", [_vm._v("Vyhľadávanie")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }