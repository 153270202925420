<template>

    <div class="title">

        <a href="javascript:history.back()" class="back-button">Späť</a>
        <h2><span>{{ code }}</span>{{ name }}</h2>

    </div>

</template>

<script>
    export default {
        props: ['code', 'name']
    }
</script>

<style>
</style>