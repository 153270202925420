var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "contact" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("p", [
        _vm._v(
          "Uveďte prosím čo najpodrobnejší popis chyby (čo ste práve v aplikácii robili, na čo ste klikli, ako sa chyba prejavila, ...)"
        )
      ]),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "form-group" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.feedbackText,
                expression: "feedbackText"
              }
            ],
            staticClass: "form-control",
            attrs: { rows: "10", placeholder: "Vaša správa" },
            domProps: { value: _vm.feedbackText },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.feedbackText = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        !_vm.btnDisabled
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary mb-3",
                attrs: { type: "submit" },
                on: { click: _vm.sendFeedback }
              },
              [_vm._v("Odoslať")]
            )
          : _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v("Odosielam...")]
            ),
        _vm._v(" "),
        _vm.successMessage ? _c("p", [_vm._v("Chyba odoslaná")]) : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h2", [_vm._v("Nahlásenie chyby v aplikácii")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }