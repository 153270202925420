import Vue from 'vue';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment'
import bugsnagClient from './bugsnag'
import bugsnagVue from '@bugsnag/plugin-vue'

Vue.use(VueMoment)

bugsnagClient.use(bugsnagVue, Vue)

if (process.env.NODE_ENV === "development") {
    Vue.prototype.$baseUrl = 'http://localhost:8080/'
    Vue.prototype.$assetsUrl = Vue.prototype.$baseUrl + 'html/dist/assets/'
    Vue.use(VueAxios, axios.create({baseURL: 'http://localhost/appo/public/api/'}))
}
else {
    Vue.prototype.$baseUrl = 'https://aplikacia.appo.sk/'
    Vue.prototype.$assetsUrl = Vue.prototype.$baseUrl + 'assets/'
    Vue.use(VueAxios, axios.create({baseURL: 'https://opk.appo.sk/api/'}))
}

router.beforeEach((to, from, next) => {

    if ( sessionStorage.getItem('okto.opk.pwa.validated') ) {
        if (to.name === 'register') {
            next({ name: 'loading' })
        }
        else {
            next()
        }
    }
    else {
        if ( to.name !== 'loading' && to.name !== 'register' && to.name !== 'error' ) {
            next({name: 'loading'})
        }
        else {
            next()
        }
    }

})

var vm = new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>',
});