var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title" }, [
    _c(
      "a",
      {
        staticClass: "back-button",
        attrs: { href: "javascript:history.back()" }
      },
      [_vm._v("Späť")]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "custom-label" }, [
      _vm._v("Názov nadradenej skupiny")
    ]),
    _vm._v(" "),
    _c("h2", [
      _c("span", [_vm._v(_vm._s(_vm.group.code))]),
      _vm._v(" " + _vm._s(_vm.group.name))
    ]),
    _vm._v(" "),
    _vm.subgroupId !== undefined
      ? _c("div", { staticClass: "mt-4" }, [
          _c("span", { staticClass: "custom-label" }, [
            _vm._v("Názov skupiny alebo podskupiny")
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("span", [
              _vm._v(_vm._s(_vm.group.subgroups[_vm.subgroupId].code))
            ]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.group.subgroups[_vm.subgroupId].name) +
                "\n        "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.sub2groupId !== undefined
      ? _c("div", { staticClass: "mt-4" }, [
          _c("span", { staticClass: "custom-label" }, [
            _vm._v("Názov skupiny alebo podskupiny")
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.group.subgroups[_vm.subgroupId].subgroups[_vm.sub2groupId]
                    .code
                )
              )
            ]),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.group.subgroups[_vm.subgroupId].subgroups[_vm.sub2groupId]
                    .name
                ) +
                "\n        "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.sub3groupId !== undefined
      ? _c("div", { staticClass: "mt-4" }, [
          _c("span", { staticClass: "custom-label" }, [
            _vm._v("Názov skupiny alebo podskupiny")
          ]),
          _vm._v(" "),
          _c("h2", [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.group.subgroups[_vm.subgroupId].subgroups[_vm.sub2groupId]
                    .subgroups[_vm.sub3groupId].code
                )
              )
            ]),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.group.subgroups[_vm.subgroupId].subgroups[_vm.sub2groupId]
                    .subgroups[_vm.sub3groupId].name
                ) +
                "\n        "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }