var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "main",
      [
        _c("router-view", {
          attrs: {
            groups: _vm.groups,
            requiredDocs: _vm.requiredDocs,
            profile: _vm.profile
          },
          on: {
            updateAttachments: _vm.apiAttachments,
            updateProfile: _vm.apiProfile,
            updateGroups: _vm.apiGroups,
            checkAndUpdateData: _vm.checkAndUpdateData
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.$route.meta.showMenu == true
      ? _c("div", { staticClass: "nav-menu" }, [
          _c("nav", [
            _c(
              "ul",
              _vm._l(_vm.links, function(link, index) {
                return _c(
                  "li",
                  { key: index },
                  [
                    _c("router-link", { attrs: { to: link.to } }, [
                      _c("img", {
                        attrs: {
                          height: "27",
                          src: _vm.$assetsUrl + link.iconSrc
                        }
                      }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(link.name) +
                          "\n                    "
                      )
                    ])
                  ],
                  1
                )
              }),
              0
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }