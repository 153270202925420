var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.result.i2 !== undefined
      ? _c("div", [
          _c("span", { staticClass: "cislo" }, [
            _vm._v(_vm._s(_vm.groups[_vm.result.i1].code))
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.groups[_vm.result.i1].name))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.result.i3 !== undefined
      ? _c("div", [
          _c("span", { staticClass: "cislo" }, [
            _vm._v(
              _vm._s(_vm.groups[_vm.result.i1].subgroups[_vm.result.i2].code)
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.groups[_vm.result.i1].subgroups[_vm.result.i2].name)
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.result.i4 !== undefined
      ? _c("div", [
          _c("span", { staticClass: "cislo" }, [
            _vm._v(
              _vm._s(
                _vm.groups[_vm.result.i1].subgroups[_vm.result.i2].subgroups[
                  _vm.result.i3
                ].code
              )
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.groups[_vm.result.i1].subgroups[_vm.result.i2].subgroups[
                  _vm.result.i3
                ].name
              )
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c("span", { staticClass: "cislo" }, [_vm._v(_vm._s(_vm.result.code))]),
      _vm._v(" "),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.highlightText(_vm.result.name)) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }