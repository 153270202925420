var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { attrs: { id: "priloha" } }, [
      _c("div", { staticClass: "title" }, [
        _c(
          "a",
          {
            staticClass: "back-button",
            attrs: { href: "javascript:history.back()" }
          },
          [_vm._v("Späť")]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "custom-label" }, [
          _vm._v("Názov dokladu prílohy")
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "mb-4" }, [
          _vm._v(_vm._s(_vm.requiredDocs[_vm.id].name))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "custom-label" }, [
          _vm._v("Kód dokladu prílohy")
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "mb-0" }, [
          _vm._v(_vm._s(_vm.requiredDocs[_vm.id].code))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("span", { staticClass: "custom-label" }, [
          _vm._v("Informácie o doklade prílohy")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.requiredDocs[_vm.id].info))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }