var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "skupina" } }, [
    _c(
      "div",
      [
        _vm.groups.length > 0
          ? _c("subgroup-detail-title", {
              attrs: {
                group: _vm.groups[_vm.groupId],
                "subgroup-id": _vm.subgroupId,
                "sub2group-id": _vm.sub2groupId,
                "sub3group-id": _vm.sub3groupId
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.sub3groupId !== undefined && _vm.groups.length > 0
          ? _c("subgroup-detail-content", {
              attrs: {
                subgroup:
                  _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].subgroups[
                    _vm.sub2groupId
                  ].subgroups[_vm.sub3groupId],
                "required-docs": _vm.requiredDocs
              }
            })
          : _vm.sub2groupId !== undefined && _vm.groups.length > 0
          ? _c("subgroup-detail-content", {
              attrs: {
                subgroup:
                  _vm.groups[_vm.groupId].subgroups[_vm.subgroupId].subgroups[
                    _vm.sub2groupId
                  ],
                "required-docs": _vm.requiredDocs
              }
            })
          : _vm.subgroupId !== undefined && _vm.groups.length > 0
          ? _c("subgroup-detail-content", {
              attrs: {
                subgroup: _vm.groups[_vm.groupId].subgroups[_vm.subgroupId],
                "required-docs": _vm.requiredDocs
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }